import React from "react"
import logo from "../assets/logo.svg"
import logoDark from "../assets/logo-dark.svg"
import { FiMenu } from "react-icons/fi"
import PageLinks from "../constants/links"
import { Link } from "gatsby"
class Navbar extends React.Component {
  
  anchoreElement;

  constructor(props) {
    super(props)

    this.myRef = React.createRef();

    this.state = {
      isDark: false,
    }
  }

  componentDidMount(){
    this.anchoreElement = document.querySelector('[data-qs=sticky-header-anchor]');

    if(this.anchoreElement) {
      window.addEventListener('scroll', this.handleScroll);  
      this.handleScroll();
    }
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const boundingRect = this.anchoreElement.getBoundingClientRect();
    const refHeight = this.myRef.current.clientHeight;

    if(boundingRect.height + boundingRect.top - refHeight / 2 <= 0 && !this.state.isDark) {
      this.setState({
        isDark: true,
      });
      return;
    }

    if(boundingRect.height + boundingRect.top - refHeight / 2 > 0 && this.state.isDark) {
      this.setState({
        isDark: false,
      });
      return;
    }
  }

  render() {
    return (
      <nav ref={this.myRef} className={`navbar ${this.state.isDark ? 'navbar--dark' : ''}`}>
        <div className="nav-center">
          <div className="nav-header">
            <Link
              to={'/'}
            >
              <img src={this.state.isDark ? logoDark : logo} alt="Logo" />
            </Link>
            <button
              type={"button"}
              className={"toggle-btn"}
              onClick={this.props.toggleSidebar}
            >
              <FiMenu />
            </button>
          </div>
          <PageLinks styleClass={"nav-links"} />
        </div>
      </nav>
    )
  }
}

export default Navbar
