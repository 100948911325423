import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteTitle: title
        twitterUsername
        siteUrl
        name
      }
    }
  }
`

const SEO = ({ title, description, pathname, image}) => {
  const {site} = useStaticQuery(query);
  const url = site.siteMetadata.siteUrl + (pathname || '/');

  return <Helmet title={`${title ? `${title} | ${site.siteMetadata.siteTitle}` : site.siteMetadata.siteTitle}`} htmlAttributes={{lang:'en'}}>
    <meta name='image' content={image || site.siteMetadata.image} />
    <meta name='description' content={description || site.siteMetadata.siteDesc} />
    <meta name="theme-color" content="#1a2545"></meta>
    {/* Twitter */}
    <meta name="twitter:title" content={`${title ? `${title} | ${site.siteMetadata.siteTitle}` : site.siteMetadata.siteTitle}`} />
    <meta name="twitter:description" content={description || site.siteMetadata.siteDesc} />
    {/* IS it page or site? */}
    <meta name="twitter:url" content={url}/>
    <meta name="twitter:image" content={image || site.siteMetadata.image}/>
    <meta name="twitter:domain" content={site.siteMetadata.name}/>
    <meta name="twitter:card" content="summary"/>
    {/* Twitter */}

    {/* OG */}
    <meta property="og:url" content={url}/>
    <meta property="og:site_name" content={site.siteMetadata.name}/>
    <meta property="og:image" content={image || site.siteMetadata.image}/>
    <meta property="og:image:type" content="image/jpeg"/>
    <meta property="og:image:width" content="1920"/>
    <meta property="og:image:height" content="1280"/>
    <meta property="og:locale" content="en_US"/>
    <meta property="og:type" content="website"/>
    <meta property="og:description" content={description || site.siteMetadata.siteDesc} />
    {/* OG */}
  </Helmet>
}

export default SEO
