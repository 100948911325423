import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className={"footer"}>
      <div>
        {/*<SocialLinks styleClass={"footer-links"} />*/}
        <h4>Built with ❤️ in Dubai, UAE 🇦🇪</h4>
        <p>Copyright &copy; {new Date().getFullYear()} Techspace.ae. All Rights Reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
