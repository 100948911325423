import React from "react"
import { Link } from "gatsby"
const data = [
  {
    id: 1,
    text: "Recruitment",
    url: "/recruitment",
  },
  {
    id: 2,
    text: "Get in touch",
    url: "#contact",
    styleClass: 'btn',
  }
  // {
  //   id: 1,
  //   text: "home",
  //   url: "/",
  // },
  // {
  //   id: 2,
  //   text: "industries",
  //   url: "/industries/",
  // },
  // {
  //   id: 3,
  //   text: "about",
  //   url: "/about/",
  // },
  // {
  //   id: 4,
  //   text: "projects",
  //   url: "/projects/",
  // },
  // {
  //   id: 5,
  //   text: "contact",
  //   url: "/contact/",
  // },
  // {
  //   id: 6,
  //   text: "blog",
  //   url: "/blog/",
  // },
]

const tempLinks = data.map(link => {
  return (
    <li key={link.id} >
      <Link to={link.url} className={link.styleClass ? link.styleClass : ""}>{link.text}</Link>
    </li>
  )
})

export default ({ styleClass, onClick }) => {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {
        data.map(link => {
          return (
            <li key={link.id} >
              <Link
                to={link.url}
                onClick={() => {
                  onClick && onClick();
                } }
                className={link.styleClass ? link.styleClass : ""}
              >{link.text}</Link>
            </li>
          )
        })
      }
    </ul>
  )
}
